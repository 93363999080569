import {CommonModule} from '@angular/common';
import {Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {ROUTES_KEYS, URL_PARAMS} from '@constant/index';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ErrorService} from '@services/error.service';
import {SubscriptionFacade} from '@store/facade/subscription-facade.service';
import {ThemeFacade} from '@store/index';
import {paramsToLower} from '@utils/query-params-utils';

@UntilDestroy()
@Component({
  selector: 'app-subscriptions',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class SubscriptionsComponent implements OnInit {
  companyName?: string;
  _activatedRouter = inject(ActivatedRoute);
  _router = inject(Router);
  _errorService = inject(ErrorService);
  _subscriptionFacade = inject(SubscriptionFacade);
  _lang: string = 'en';
  _livemode: boolean = false;
  _themeFacade = inject(ThemeFacade);

  constructor() {
    this._subscriptionFacade.selectSubscrition$
      .pipe(untilDestroyed(this))
      .subscribe(sub => {
        if (sub) {
          const {companyName} = sub;

          this.companyName = companyName;
        }
      });

    // on Initialize success .. get back the paymentid .. and pass to mormal flow
    this._subscriptionFacade.selectPaymentId$
      .pipe(untilDestroyed(this))
      .subscribe(paymentId => {
        if (paymentId) {
          this._router.navigate([ROUTES_KEYS.HOME], {
            queryParams: {
              paymentId,
              lang: this._lang,
              livemode: this._livemode,
            },
          });
        }
      });

    //on Initialize error
    this._subscriptionFacade.selectErrorInitialize$
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        this._errorService.Clear();

        this._errorService.Set(error);
        this._router.navigate([ROUTES_KEYS.ERROR]);
      });
  }

  ngOnInit(): void {
    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => {
        const res = paramsToLower(params);
        this._livemode = res[URL_PARAMS.LIVE_MODE];
        this._lang = res[URL_PARAMS.LANG];

        const subscriptionId = res[URL_PARAMS.SUBSCRIPTION_ID];
        const externalId = res[URL_PARAMS.EXTERNAL_ID];
        const cancelUrl = res[URL_PARAMS.CANCEL_URL];
        const returnUrl = res[URL_PARAMS.RETURN_URL];

        if (subscriptionId) {
          this._subscriptionFacade.getSubscription(subscriptionId);
          this._subscriptionFacade.initialization({
            id: subscriptionId,
            externalId,
            cancelUrl,
            returnUrl,
          });
        }
      });
  }
}
