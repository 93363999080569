import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {
  BKN_3DS_STATUS,
  LOCALSTORAGE_KEYS,
  PAYMENT_GATEWAY_STATES,
  PAYMENT_IDS,
  ROUTES_KEYS,
  URL_PARAMS,
} from '@constant/index';
import {environment} from '@env/environment';
import {
  AuthorizeBknRequestDto,
  AuthorizeBknResponseDto,
  BknPaymentForm,
  ChargesRequestDto,
  CreditCardDto,
  PaymentDto,
  PaymentGatewaysDto,
  SubmerchantDto,
} from '@models/index';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  CreateOrderActions,
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js';
import {ChargesService} from '@services/charges.service';
import {ErrorService} from '@services/error.service';
import {Frame3dsService} from '@services/frame3ds.service';
import {LocalStorageService} from '@services/localstorage.service';
import {PaymentService} from '@services/payment.service';
import {PayPalService} from '@services/paypal/pay-pal.service';
import {ChargesActions, PaymentActions} from '@store/actions';
import {ChargesFacade} from '@store/facade/charges-facade.service';
import {PaymentFacade} from '@store/facade/payment-facade.service';
import {SubmerchantFacade} from '@store/facade/submerchant-facade.service';
import {ThemeFacade} from '@store/facade/theme-facade.service';
import {PaymentReducers} from '@store/reducers';
import {paymentSelectors} from '@store/selectors';
import {PaymentMethods} from '@type/payment-methods.type';
import {paramsToLower} from '@utils/query-params-utils';
import {RSAHelper} from '@utils/rsa-helper';
import loadjs from 'loadjs';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {BehaviorSubject, filter, Observable, of, take, tap} from 'rxjs';
import {BknPaymentFormComponent} from '../../components/bkn-payment-form/bkn-payment-form.component';

declare var dftp: any;

@UntilDestroy()
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzRadioModule,
    FormsModule,
    BknPaymentFormComponent,
    NzSpinModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  _submerchantFacade = inject(SubmerchantFacade);
  _paymentFacade = inject(PaymentFacade);
  _chargeFacade = inject(ChargesFacade);
  _router = inject(Router);
  _activatedRouter = inject(ActivatedRoute);
  _localStorageService = inject(LocalStorageService);
  _paypalService = inject(PayPalService);
  _errorService = inject(ErrorService);
  _paymentService = inject(PaymentService);
  _chargesService = inject(ChargesService);
  _store = inject(Store<PaymentReducers.State>);
  _rsaHelper = inject(RSAHelper);
  _themeFacade = inject(ThemeFacade);
  subMerchant$?: Observable<SubmerchantDto | null | undefined>;
  paymentGatewas$?: Observable<PaymentGatewaysDto[] | null | undefined>;
  translate = inject(TranslateService);
  isLoading$?: Observable<boolean>;
  payment$?: Observable<PaymentDto | null | undefined>;

  _frameService = inject(Frame3dsService);
  sanitizer = inject(DomSanitizer);
  iframeUrl?: SafeResourceUrl;
  isLoading: boolean = false;
  payButtonState: boolean = false;
  paypalButtonState: boolean = false;
  lodadedJs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isLoadedPaypalJS: boolean = false;

  selectedPaymentMethod: PaymentMethods | null = null;
  selectedIndex: number = 0;
  selectedCard: {
    id: string | undefined;
    value: boolean;
    route: string;
    paymentId: string;
  }[] = [];

  private _paymentId?: string;
  private _livemode: boolean = false;
  private _lang: string = 'en';

  private _alreadyRendered: boolean = false;
  private _formValue?: BknPaymentForm;

  @ViewChild('frame', {static: true}) iframe!: ElementRef<HTMLIFrameElement>;
  callbackfn = (event: any) => {
    if (event) {
      this.isLoading$ = of(true);
      //call authorize
      this.onSubmit(event);
    }
  };

  constructor() {
    this._chargeFacade.reset();
    this._chargeFacade.resetAuthorize();

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

    this.subMerchant$ = this._submerchantFacade.Submerchant$.pipe(
      untilDestroyed(this),
      tap(async responses => {
        const item = responses;

        if (!item || !this._paymentId) return;

        if (
          item.paymentGateways &&
          item.paymentGateways.length > 0 &&
          !!item.paymentGateways[0]
        ) {
          const paymentMethod = item.paymentGateways[0].id;
          this._paymentService.SetGatewayPaymentId(paymentMethod);
          this.selectedPaymentMethod = paymentMethod;

          if (paymentMethod === PAYMENT_IDS.GCGE) {
            //gc-ge
            this._router.navigate([ROUTES_KEYS.GCGE_PAYMENT], {
              queryParams: {
                paymentId: this._paymentId,
                livemode: this._livemode,
                lang: this._lang,
              },
            });
          }
        }

        await this.InitPaylPal(item);
      })
    );

    this._paymentFacade.PaymentLink$.pipe(untilDestroyed(this)).subscribe(
      async resp => {
        if (resp?.paymentId) {
          const route = await this._router.navigate([ROUTES_KEYS.HOME], {
            queryParams: {
              paymentId: resp.paymentId,
              lang: this._lang,
              livemode: this._livemode,
            },
          });

          if (route) window.location.reload();
        }
      }
    );

    this._paymentFacade.Cards$.pipe(untilDestroyed(this)).subscribe(cards => {
      this.selectedCard = cards;
    });

    this._chargeFacade.Charges$.pipe(untilDestroyed(this)).subscribe(charge => {
      if (charge?.id) {
        localStorage.setItem(LOCALSTORAGE_KEYS.CHARGE_ID, charge.id);

        const options = {
          attemptReference: charge?.id,
          sensitiveFields: ['securityCode'],
        };

        const dftpPromise = loadjs(environment.NETHONJS_URL, {
          returnPromise: true,
          before: (path: any, scriptEl: any) =>
            (scriptEl.crossOrigin = 'use-credentials'),
        })
          .then(() => dftp.init(options))
          .catch(err => console.error(err));

        dftpPromise
          .then(() => dftp.profileCompleted())
          .catch(err => console.error('Profiling failed with err: ' + err));
      }
    });

    this._chargeFacade.Authorize$.pipe(untilDestroyed(this)).subscribe(
      authorize => {
        this._store.dispatch(ChargesActions.authorizeReset());

        if (!authorize) {
          //loading stop
          this._store.dispatch(
            ChargesActions.loadingAuthorizeBkn({
              isLoading: false,
            })
          );

          this._router.navigate([ROUTES_KEYS.ERROR], {
            queryParams: {
              paymentId: this._paymentId,
              lang: this._lang,
              livemode: this._livemode,
            },
          });
          return;
        }

        // NOT IN 3DS MODE .. so if is success .. go to thankyou
        this.checkIframeOrRedirect(authorize);
      }
    );

    this._chargeFacade.AuthorizeError$.pipe(untilDestroyed(this)).subscribe(
      error => {
        this._store.dispatch(ChargesActions.authorizeReset());

        if (error) {
          this._errorService.Set(error);

          this._router.navigate([ROUTES_KEYS.ERROR], {
            queryParams: {
              paymentId: this._paymentId,
              lang: this._lang,
              livemode: this._livemode,
            },
          });
        }
      }
    );

    // PAYMENT INFO
    this.payment$ = this._store.pipe(
      untilDestroyed(this),
      select(paymentSelectors.selectFirstOrDefaulPayment),
      filter(item => !!item)
    );

    this._paymentFacade.Cards$.pipe(
      untilDestroyed(this),
      tap((xx: any) => this.checkForDefaultPayment(xx))
    ).subscribe();
  }

  ngOnInit(): void {
    this._activatedRouter.root.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(item => {
        const res = paramsToLower(item);

        const paymentId = res[URL_PARAMS.PAYMENT_ID];
        const idLink = res[URL_PARAMS.PAYMENT_LINK_ID];

        this._livemode = res[URL_PARAMS.LIVE_MODE];
        this._lang = res[URL_PARAMS.LANG];

        if (paymentId) {
          this._paymentId = paymentId;
          this._submerchantFacade.GetSubmerchant(paymentId);
          this._paymentService.SetPaymentId(paymentId);
          this._themeFacade.getTheme(paymentId);

          // Get payment by id
          this._paymentFacade.CreatePayment(paymentId);

          // get payment methods availables
          this._paymentFacade.Cards$.pipe(
            take(1), // Take only the first emission
            untilDestroyed(this)
          ).subscribe(cards => {
            if (cards[0]?.id !== PAYMENT_IDS.GCGE) {
              this._chargeFacade.chargesCreate({
                paymentGatewayId: 'nets',
                paymentId,
              } as ChargesRequestDto);
            }
          });
        }

        if (idLink) {
          sessionStorage.clear();
          this._paymentFacade.CreatePaymentLink(idLink);
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.iframe) {
      window.addEventListener(
        'message',
        (event: any) => {
          if (event?.data == 'loaded' && this._formValue) {
            this.isLoading$ = of(true);
            //call authorize
            this.onSubmit(this._formValue!);
          }
        },
        false
      );
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('loaded', (event: any) => {
      // console.log('removed');
    });
  }

  handleChangePaymentMethod(ev: any) {
    if (ev == PAYMENT_IDS.PAYPAL) {
      this.onSelectPayPal();
    }
  }

  onSelect(ev: any) {
    this.selectedCard.forEach(x => (x.value = false));

    const index = this.selectedCard.findIndex(x => x.id == ev.paymentGatewayId);
    if (index >= 0) {
      this.selectedCard[index].value = ev.isSelected;
      this.selectedIndex = index;
    } else {
      this.selectedCard.push({
        id: ev.paymentGatewayId,
        value: ev.isSelected,
        route: ev.route,
        paymentId: ev.paymentId,
      });

      this.selectedIndex = 0;
    }

    this.payButtonState = ev.isSelected;
    this.paypalButtonState = false;
    this._alreadyRendered = false;
  }

  onSelectPayPal() {
    if (!this._alreadyRendered) {
      this.onPayPalRenderDiv(true);
    }
  }

  onPayPalRenderDiv(isRendered: boolean) {
    if (!isRendered || !this._paymentId) return;
    this.loadPayPalBtn(this._paymentId);
  }

  onSubmit(ev: BknPaymentForm) {
    this._formValue = ev;
    this.reset();

    const chargeId = localStorage.getItem(LOCALSTORAGE_KEYS.CHARGE_ID);

    this._store.dispatch(
      ChargesActions.loadingAuthorizeBkn({
        isLoading: true,
      })
    );

    const {
      cardHolderName,
      creditCardNumber,
      expiredMonth,
      expiredYear,
      securityCode,
      subscriber,
    } = ev as CreditCardDto;

    const request = {
      chargesId: chargeId,
      body: {
        cardHolderName,
        creditCardNumber:
          this._rsaHelper.encryptWithPublicKey(creditCardNumber),
        expiredMonth,
        expiredYear: `${20}${expiredYear}`,
        securityCode: this._rsaHelper.encryptWithPublicKey(securityCode),
        authNotifyURL:
          window.origin + '/iframeclose.html' + window.location.search,
        gdiNotifyURL: window.origin + '/gdiiframe.html',
        subscriber,
      } as CreditCardDto,
    } as AuthorizeBknRequestDto;

    if (chargeId) {
      this._chargesService.SaveAuthorizeBkn(request);
      //get payment methods availables
      this._store.dispatch(
        ChargesActions.authorizeBkn({
          request: request,
        })
      );
    } else {
      this._errorService.SetMessage(
        this.translate.instant('ERRORS.payment-id-missing')
      );

      this._router.navigate([ROUTES_KEYS.ERROR], {
        queryParams: {
          paymentId: this._paymentId,
          lang: this._lang,
          livemode: this._livemode,
        },
      });
    }
  }

  private InitPaylPal = async (sub: SubmerchantDto) => {
    return new Promise((resolve, reject) => {
      if (
        sub?.currency &&
        this._paymentId &&
        sub.paymentGateways?.some(py => py.id == PAYMENT_IDS.PAYPAL) &&
        sub.paymentGateways?.find(py => py.id == PAYMENT_IDS.PAYPAL)?.state ==
          PAYMENT_GATEWAY_STATES.CONNECTED &&
        sub.paymentGateways?.find(py => py.id == PAYMENT_IDS.PAYPAL)
          ?.configuration
      ) {
        const metadata = sub!.paymentGateways!.find(
          py => py.id == PAYMENT_IDS.PAYPAL
        )!.configuration;

        if (metadata && metadata['merchantId'] && !this._isLoadedPaypalJS) {
          this._paypalService
            .load(sub.currency, metadata['merchantId'])
            .then(() => {
              this.lodadedJs.next(true);
              this._isLoadedPaypalJS = true;
              resolve(this.lodadedJs);
            })
            .catch(err => {
              reject(err);
            });
        }
      }
    });
  };

  private checkForDefaultPayment(
    resp: PaymentGatewaysDto[] | null | undefined
  ) {
    //CHECK FOR ID
    if (resp?.length == 1) {
      const onePaymentType = resp[0];

      if (onePaymentType.id === PAYMENT_IDS.GCGE) {
        //gc-ge

        this._router.navigate([ROUTES_KEYS.GCGE_PAYMENT]);
      } else {
        //nets
        this._paymentService.SetGatewayPaymentId(onePaymentType.id);
        if (this._paymentId)
          this._router.navigate(
            [ROUTES_KEYS.PAYMENT.GATEWAY(onePaymentType.route)],
            {
              queryParams: {
                paymentId: this._paymentId,
                livemode: this._livemode,
                lang: this._lang,
              },
            }
          );
        else
          this._router.navigate([
            ROUTES_KEYS.PAYMENT.GATEWAY(onePaymentType.route),
          ]);
      }
    }
  }

  private checkIframeOrRedirect(authorize: AuthorizeBknResponseDto) {
    if (!authorize?.success) {
      this.goToerror(authorize);
      return;
    } else {
      //SUCCESS
      if (authorize.gatewayCode == BKN_3DS_STATUS.DS2.CHALLENGE) {
        return;
      }

      if (authorize.returnUrl) {
        if (authorize.tranStatus == BKN_3DS_STATUS.DS2.CHALLENGE) {
          //loading stop
          this.goToOtp(authorize);
          return;
        }

        //check if has NOt the transStatus == "CHALLENGE" .. so set returnUrl in iframe hidden
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          authorize.returnUrl
        );

        this.iframe.nativeElement.src = authorize.returnUrl;
        //check if HAS the transStatus == "CHALLENGE" .. so sredirect page on returnURl
      } else if (!authorize.returnUrl) {
        this._router.navigate([ROUTES_KEYS.NETS.thankyou], {
          queryParams: {
            paymentId: this._paymentId,
            lang: this._lang,
            livemode: this._livemode,
          },
        });
      }
    }
  }

  private goToOtp(authorize: AuthorizeBknResponseDto) {
    this._store.dispatch(
      ChargesActions.loadingAuthorizeBkn({
        isLoading: false,
      })
    );

    this._frameService.setOtpUri(authorize.returnUrl!);
    this._router.navigate([ROUTES_KEYS.NETS.otp], {
      queryParams: {
        paymentId: this._paymentId,
        lang: this._lang,
        livemode: this._livemode,
      },
    });
  }

  private goToerror(authorize: AuthorizeBknResponseDto) {
    const error = authorize.gatewayCode
      ? this.translateError(authorize.gatewayCode)
      : 'ERRORS.INTERNAL_SERVER_ERROR.SomethingWentWrong';

    this._errorService.SetMessage(error);

    //loading stop
    this._store.dispatch(
      ChargesActions.loadingAuthorizeBkn({
        isLoading: false,
      })
    );

    this._router.navigate([ROUTES_KEYS.ERROR], {
      queryParams: {
        paymentId: this._paymentId,
        lang: this._lang,
        livemode: this._livemode,
      },
    });
  }

  private reset() {
    this._store.dispatch(PaymentActions.paymentReset());
    this._store.dispatch(PaymentActions.submerchantReset());

    this._store.dispatch(ChargesActions.chargesCreateReset());
    this._store.dispatch(PaymentActions.createPaymentLinkReset());
  }

  private translateError(error: string) {
    let traslated = this.translate.instant(error);
    traslated = traslated ? traslated : error.replace('_', ' ');

    return traslated;
  }

  private loadPayPalBtn(paymentId: string) {
    const paypal = this._paypalService.isLoaded();

    this._alreadyRendered = true;

    if (paypal && this.lodadedJs.value) {
      // @ts-ignore
      paypal!
        .Buttons({
          style: {
            shape: 'rect',
            label: 'pay',
            height: 55,
            layout: 'horizontal',
          },
          //Call your server to set up the transaction
          createOrder: (
            data: Record<string, unknown>,
            actions: CreateOrderActions
          ) => {
            return new Promise((resolve, reject) => {
              this._chargesService
                .Create({
                  paymentGatewayId: PAYMENT_IDS.PAYPAL,
                  paymentId,
                } as ChargesRequestDto)
                .subscribe(
                  orderData => {
                    if (orderData?.metadata) {
                      this._paymentService.SetChargeId(orderData.id);

                      resolve(orderData?.metadata['orderId']);
                    } else reject('no order id');
                  },
                  err => {
                    reject(err);
                  }
                );
            });
          },
          // Call your server to finalize the transaction
          onApprove: (data: OnApproveData, actions: OnApproveActions) => {
            return new Promise((resolve, reject) => {
              const chargeId = this._paymentService.GetChargeId();
              data.paymentID = chargeId;
              this._chargesService.Capture(data).subscribe(
                orderData => {
                  if (orderData && orderData.restartOperation) {
                    return actions.restart();
                  } else if (orderData && orderData.success) {
                    this._router.navigate([ROUTES_KEYS.PAYPAL.thankyou], {
                      queryParams: {
                        paymentId: this._paymentId,
                        lang: this._lang,
                        livemode: this._livemode,
                      },
                    });
                  } else {
                    this._router.navigate([ROUTES_KEYS.ERROR], {
                      queryParams: {
                        paymentId: this._paymentId,
                        lang: this._lang,
                        livemode: this._livemode,
                      },
                    });
                  }
                },
                err => {
                  this._router.navigate([ROUTES_KEYS.ERROR], {
                    queryParams: {
                      paymentId: this._paymentId,
                      lang: this._lang,
                      livemode: this._livemode,
                    },
                  });
                }
              );
            });
          },
          onClick: (ev: any) => {
            this._paymentService.SetGatewayPaymentId(LOCALSTORAGE_KEYS.PAYPAL);
          },
          onCancel: (data: any) => {
            // Show a cancel page, or return to cart
            window.location.reload();
          },
          onError: (err: any) => {
            // For example, redirect to a specific error page
            // window.location.href = '/your-error-page-here';
            this._errorService.SetMessage(err);
            this._router.navigate([ROUTES_KEYS.ERROR], {
              queryParams: {
                paymentId: this._paymentId,
                lang: this._lang,
                livemode: this._livemode,
              },
            });
          },
        })

        .render('#paypal-button-container')

        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
