import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OnApproveData} from '@paypal/paypal-js';
import {Observable} from 'rxjs';

import {environment} from '@env/environment';
import {AuthorizeResponseDto} from '../models';
import {
  AuthorizeBknRequestDto,
  AuthorizeDto,
  AuthorizeRequestDto,
  ChargesDto,
  ChargesRequestDto,
} from '../models/data-contracts';
import {ResponseCapture} from '../models/paypal';
import {MultilinguaService} from './multilingua.service';

@Injectable({
  providedIn: 'root',
})
export class ChargesService {
  constructor(
    private _http: HttpClient,
    private readonly _multiLanguageService: MultilinguaService
  ) {}

  Get(id: string): Observable<AuthorizeResponseDto> {
    return this._http.get<AuthorizeResponseDto>(
      environment.API_URL + `charges/${id}`
    );
  }

  Create(input: ChargesRequestDto): Observable<ChargesDto> {
    return this._http.post<ChargesDto>(
      environment.API_URL + `${input.paymentGatewayId}/charges`,
      input as Omit<ChargesDto, 'paymentGatewayId'>
    );
  }

  Capture(data: OnApproveData): Observable<ResponseCapture> {
    return this._http.post<ResponseCapture>(
      environment.API_URL + `paypal/capture`,
      {
        orderId: data.orderID,
        chargeId: data.paymentID,
        payerId: data.payerID,
      }
    );
  }

  Authorize(input: AuthorizeRequestDto): Observable<AuthorizeDto> {
    return this._http.post<AuthorizeDto>(
      environment.API_URL +
        `mastercard/charges/${input.chargesId}/authorizations`,
      input
    );
  }

  AuthorizeBkn(input: AuthorizeBknRequestDto): Observable<AuthorizeDto> {
    const preffered_lang = this._multiLanguageService.getChoosenLang();
    const {
      body: {
        cardHolderName,
        creditCardNumber,
        securityCode,
        expiredMonth,
        expiredYear,
        authNotifyURL,
        gdiNotifyURL,
        subscriber,
      } = {},
    } = input;

    return this._http.post<AuthorizeDto>(
      environment.API_URL + `nets/charges/${input.chargesId}/authorizations`,
      {
        cardHolderName,
        creditCardNumber,
        securityCode,
        expiredMonth,
        expiredYear,
        gdiNotifyURL,
        authNotifyURL,
        subscriber,
      },
      {headers: {'x-PreferredLanguage': preffered_lang}}
    );
  }

  GetAuthorizeBknFromSession(): AuthorizeBknRequestDto | null | undefined {
    return localStorage.getItem(`authorizedata`)
      ? (JSON.parse(
          localStorage.getItem(`authorizedata`)!
        ) as AuthorizeBknRequestDto)
      : undefined;
  }

  SaveAuthorizeBkn(input: AuthorizeBknRequestDto) {
    // const keys = Object.keys(localStorage).filter(x =>
    //   localStorage?.getItem(x)?.startsWith('authorizedata_')
    // );

    // keys.forEach(kk => {
    //   localStorage.removeItem(kk);
    // });

    localStorage.setItem(`authorizedata`, JSON.stringify(input));
  }
}
