import {createAction, props} from '@ngrx/store';
import {SubscriberNew, SubscriptionForm} from '../../models';

export const initialization = createAction(
  '[subscribers] initialization',
  props<{
    id: string;
    externalId?: string;
    cancelUrl?: string;
    returnUrl?: string;
  }>()
);

export const initializationSuccess = createAction(
  '[subscribers] initialization Success',
  props<{id: string}>()
);

export const initializationError = createAction(
  '[subscribers] initialization Error',
  props<any>()
);

export const initializationReset = createAction(
  '[subscribers] initialization Reset'
);

export const Subcriberinitialization = createAction(
  '[subscribers] subscriber initialization',
  props<{
    id: string;
  }>()
);
export const SubcriberinitializationSuccess = createAction(
  '[subscribers] subscriber initialization Success',
  props<{id: string}>()
);

export const SubcriberinitializationError = createAction(
  '[subscribers] subscriber initialization Error',
  props<any>()
);

export const SubcriberinitializationReset = createAction(
  '[subscribers] subscriber initialization Reset'
);

export const subscription = createAction(
  '[subscribers] subscription',
  props<{id: string}>()
);

export const subscriptionSuccess = createAction(
  '[subscribers] subscription Success',
  props<{subscription: SubscriptionForm}>()
);

export const subscriptionError = createAction(
  '[subscribers] subscription Error',
  props<any>()
);

export const subscriptionReset = createAction(
  '[subscribers] subscription Reset'
);

export const addSubscriber = createAction(
  '[subscribers] addSubscriber',
  props<{subscriber: SubscriberNew}>()
);

export const addSubscriberSuccess = createAction(
  '[subscribers] addSubscriber Success',
  props<{id: string; paymentSetupRedirectUrl: string}>()
);

export const addSubscriberError = createAction(
  '[subscribers] addSubscriber Error',
  props<any>()
);

export const addSubscriberReset = createAction(
  '[subscribers] addSubscriber Reset'
);
